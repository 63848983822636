import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
class About extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Danton Nguyen: About Me" />
        <article className="about">
          <h1 className="title">about</h1>
          <p>
            I got my start at a young age playing around with theming on the now
            defunct website www.AsianAvenue.com in the 2000s
          </p>
          <p>
            <time className="about__date">2014</time> - Through an engineering
            program I was reintroduced to programming, specifically C and
            discovered my proficiency for it and reignited my interests in web
            development.
          </p>
          <p>
            <time className="about__date">2016</time> - I enrolled into a
            Javascript Web Developer bootcamp at{" "}
            <a
              alt="Link to Lighthouse Labs web bootcamp page"
              href="https://lighthouselabs.ca/web-bootcamp"
            >
              Lightouse Labs
            </a>{" "}
            in Vancouver.
          </p>
          <p>
            <time className="about__date">2017</time> - I was hired out of
            bootcamp at my first professional web development job at The Jibe.
            Here I was exposed to client projects all based off e-commerce
            vendors.
          </p>
        </article>
      </Layout>
    )
  }
}

export default About
